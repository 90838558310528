import React from 'react';
import Person from './person/person';
import styles from './team.module.css';
import safdarAliKhan from '../../../images/team/safdar-ali-khan.png';
import goharAli from '../../../images/team/gohar-ali.png';
import gurdipVirdy from '../../../images/team/gurdip-virdy.png';
import lumpoRajakulendran from '../../../images/team/lumpo-rajakulendran.png';
import muhammadFarooq from '../../../images/team/muhammad-farooq.png';
import maleAvatar from '../../../images/team/male-avatar.png';
import femaleAvatar from '../../../images/team/female-avatar.png';

export default function Team() {
    return (
        <div className={styles.team}>
            <Person
                image={safdarAliKhan}
                name="Mr. Safdar Ali Khan"
                role="Principal Solicitor / Director"
            />

            <Person
                image={lumpoRajakulendran}
                name="Mr. Lumpotharan Rajakulendran"
                role="Solicitor"
            />

            <Person image={maleAvatar} name="Kumen Naido" role="Solicitor" />
            <Person image={femaleAvatar} name="Jameela" role="Solicitor" />
            <Person
                image={maleAvatar}
                name="Mandeep Randhawa"
                role="Solicitor"
            />

            <Person
                image={femaleAvatar}
                name="Huma Javed Malik"
                role="Trainee Solicitor"
            />

            <Person
                image={femaleAvatar}
                name="Akshita Obhan"
                role="Legal Clerk"
            />

            <Person image={maleAvatar} name="Afaq Ahmed" role="Legal Clerk" />
        </div>
    );
}
