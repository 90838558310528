import React from "react"
import styles from "./person.module.css"

export default function Person({ image, name, role }) {
  return (
    <div className={styles.person}>
      <img className={styles.avatar} src={image} alt="" />

      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.role}>{role}</div>
      </div>
    </div>
  )
}
