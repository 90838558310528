import React from "react"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/about/links/links"
import Team from "../../components/about/team/team"

export default function OurTeam({ data }) {
  return (
    <Layout>
      <SEO title="Our Team" />
      <Banner
        image={
          <Img
            fluid={data.image.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="About Us"
      />
      <div className="width75">
        <h1 className="title">Our Team</h1>
        <Links type="team" />
        <div className="ribbon-top">
          <Team />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    image: file(relativePath: { eq: "lawers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
